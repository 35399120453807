import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type Item = {
  label: string;
  path: string;
};

function NavMenuItem(props: Item) {
  return (
    <Box
      as={Link}
      to={props.path}
      p={"4"}
      _hover={{ bg: "blueAlpha.300" }}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Text color={"blue.800"} fontSize={"lg"}>{props.label}</Text>
      <ArrowForwardIcon color={"blue.800"} />
    </Box>
  );
}

export default NavMenuItem;
