import axios from "axios";

const api = axios.create({
  baseURL: "https://api.geloesquimo.com.br/v1/",
});

api.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${localStorage.getItem("@App:token")}`
    }
    return config
  }, error => {
    Promise.reject(error);
  }
)

export default api;
